<div fxLayout="column" fxFlex id="main" *ngIf="isLoggedIn()" [ngClass]="'theme-'+environmentName">
  <!-- l'id main è necessario per agganciare gli enventi cordova a quelli angular -->
  <mat-toolbar class="mat-elevation-z5" color="primary" style="z-index: 100!important;">
    <button mat-button (click)="menu.toggle()">
      <mat-icon>menu</mat-icon>
    </button>
    <span fxFlex fxHide.xs>&nbsp;{{tenant?.description}}</span>
    <span fxFlex fxHide fxShow.xs>&nbsp;{{tenant?.mobileDesc}}</span>
    <a [routerLink]="['']" fxShow fxHide.xs><img fxFlex="none" src="./assets/img/logo-jenia-tondo.png"
        style="height: 48px;" /></a>
    <button mat-button fxHide fxShow.xs [routerLink]="['']">
      <mat-icon>home</mat-icon>
    </button>
  </mat-toolbar>
  <div fxLayout="row" fxFlex>
    <mat-sidenav-container fxFlex fxLayout="row" hasBackdrop="true">
      <mat-sidenav #menu mode="over" opened="false">
        <mat-accordion>
          <mat-list fxLayoutAlign="space-around center" fxLayout="column">
            <mat-list-item>
              <button mat-button fxFlex fxLayoutAlign="start center" [routerLink]="['']" (click)="menu.toggle()">
                <mat-icon>home</mat-icon>&nbsp;Home
              </button>
            </mat-list-item>
            <mat-expansion-panel style="width:100%;box-shadow: none;">
              <mat-expansion-panel-header>Provider</mat-expansion-panel-header>
                <mat-accordion>
                  <mat-expansion-panel style="width:100%;box-shadow: none;">
                    <mat-expansion-panel-header>CoinMarketCap</mat-expansion-panel-header>
                      <mat-list-item>
                        <button mat-button fxFlex fxLayoutAlign="start center" [routerLink]="['/assetMappingConfiguration']"
                        (click)="menu.toggle()">
                        <mat-icon>settings</mat-icon>&nbsp;Asset Mapping
                      </button>
                      </mat-list-item>
                      <mat-list-item>
                        <button mat-button fxFlex fxLayoutAlign="start center" [routerLink]="['/coinmarketcapAssetCache']"
                        (click)="menu.toggle()">
                        <mat-icon>cached</mat-icon>&nbsp;Asset Cache
                      </button>
                      </mat-list-item>
                  </mat-expansion-panel>
                  <mat-expansion-panel style="width:100%;box-shadow: none;">
                    <mat-expansion-panel-header>WeissRatings</mat-expansion-panel-header>
                    <mat-list-item>
                      <button mat-button fxFlex fxLayoutAlign="start center"
                        [routerLink]="['/weisscryptoAssetMappingConfiguration']" (click)="menu.toggle()">
                        <mat-icon>settings</mat-icon>&nbsp;Asset Mapping
                      </button>
                    </mat-list-item>
                    <mat-list-item>
                      <button mat-button fxFlex fxLayoutAlign="start center" [routerLink]="['/weisscryptoAssetCache']"
                        (click)="menu.toggle()">
                        <mat-icon>cached</mat-icon>&nbsp;Asset Cache
                      </button>
                    </mat-list-item>
                  </mat-expansion-panel>
                  <mat-expansion-panel style="width:100%;box-shadow: none;">
                    <mat-expansion-panel-header>Cryptocompare </mat-expansion-panel-header>
                    <mat-list-item>
                      <button mat-button fxFlex fxLayoutAlign="start center"
                        [routerLink]="['/timeseriesDownloadConfiguration']" (click)="menu.toggle()">
                        <mat-icon>history</mat-icon>&nbsp;Timeseries
                      </button>
                    </mat-list-item>
                    <mat-list-item>
                      <button mat-button fxFlex fxLayoutAlign="start center" [routerLink]="['/cryptocompareAssetCache']"
                        (click)="menu.toggle()">
                        <mat-icon>cached</mat-icon>&nbsp;Asset Cache
                      </button>
                    </mat-list-item>
                  </mat-expansion-panel>
                  <mat-expansion-panel style="width:100%;box-shadow: none;">
                    <mat-expansion-panel-header>
                      AlternativeMe
                    </mat-expansion-panel-header>
                    <mat-list-item>
                      <button mat-button fxFlex fxLayoutAlign="start center" [routerLink]="['/alternativeMeFearAndGreed']"
                        (click)="menu.toggle()">
                        <mat-icon>timeline</mat-icon>&nbsp;Fear & Greed
                      </button>
                    </mat-list-item> 
                  </mat-expansion-panel>
                </mat-accordion>              
            </mat-expansion-panel>
            
            <mat-expansion-panel style="width:100%;box-shadow: none;">
              <mat-expansion-panel-header>Wallets & Exchanges</mat-expansion-panel-header>
              <mat-panel-description>
                Gestione di Exchanges e Wallets
              </mat-panel-description>
              <mat-list-item>
                <button mat-button fxFlex fxLayoutAlign="start center" [routerLink]="['/positions-container']"
                  (click)="menu.toggle()">
                  <mat-icon>supervisor_account</mat-icon>&nbsp;Positions
                </button>
              </mat-list-item>
            </mat-expansion-panel>
            <mat-expansion-panel style="width:100%;box-shadow: none;">
              <mat-expansion-panel-header>Errors</mat-expansion-panel-header>
              <mat-panel-description>
                Le mail di errore che arrivano agli sviluppatori
              </mat-panel-description>
              <mat-list-item>
                <button mat-button fxFlex fxLayoutAlign="start center" [routerLink]="['/asyncProcessWarningEvent']"
                  (click)="menu.toggle()">
                  <mat-icon>warning</mat-icon>&nbsp;Warnings
                </button>
              </mat-list-item>
              <mat-list-item>
                <button mat-button fxFlex fxLayoutAlign="start center" [routerLink]="['/asyncProcessErrorEvent']"
                  (click)="menu.toggle()">
                  <mat-icon>error</mat-icon>&nbsp;Errors
                </button>
              </mat-list-item>
            </mat-expansion-panel>
            <mat-expansion-panel style="width:100%;box-shadow: none;">
              <mat-expansion-panel-header>Timeseries</mat-expansion-panel-header>
              <mat-panel-description>
                Le serie storiche scaricate da BitDiver
              </mat-panel-description>
              <mat-list-item>
                <button mat-button fxFlex fxLayoutAlign="start center"
                  [routerLink]="['/timeseriesDataVisualizationComponent']" (click)="menu.toggle()">
                  <mat-icon>history</mat-icon>&nbsp;Grafico Timeseries
                </button>
              </mat-list-item>
            </mat-expansion-panel>
            <mat-expansion-panel style="width:100%; box-shadow: none;">
              <mat-expansion-panel-header>Catalog</mat-expansion-panel-header>
              <mat-panel-description>
                Scheda Prodotto e Censimento SmartContract
              </mat-panel-description>
              <mat-list-item>
                <button mat-button fxFlex fxLayoutAlign="start center" [routerLink]="['/assetDefinition']"
                  (click)="menu.toggle()">
                  <mat-icon>description</mat-icon>&nbsp;Asset Definition
                </button>
              </mat-list-item>
              <mat-list-item>
                <button mat-button fxFlex fxLayoutAlign="start center" [routerLink]="['/smartContractAddressMapping']"
                  (click)="menu.toggle()">
                  <mat-icon>swap_horiz</mat-icon>&nbsp;Contract Mapping
                </button>
              </mat-list-item>
              <mat-list-item>
                <button mat-button fxFlex fxLayoutAlign="start center" [routerLink]="['/dataintegration']"
                  (click)="menu.toggle()">
                  <mat-icon>data_usage</mat-icon>&nbsp;Data Integration Catalog
                </button>
              </mat-list-item>
              <mat-list-item>
                <button mat-button fxFlex fxLayoutAlign="start center" [routerLink]="['/symbolMapping/detail']"
                  (click)="menu.toggle()">
                  <mat-icon>add_circle</mat-icon>&nbsp;Symbol Mapping
                </button>
              </mat-list-item>
              <mat-list-item>
                <button mat-button fxFlex fxLayoutAlign="start center" [routerLink]="['/checkForManagedSymbols/detail']"
                  (click)="menu.toggle()">
                  <mat-icon>check_circle</mat-icon>&nbsp;Symbol Check
                </button>
              </mat-list-item>
              <mat-list-item>
                <button mat-button fxFlex fxLayoutAlign="start center" [routerLink]="['/assetDefinitionAliasMapping']"
                  (click)="menu.toggle()">
                  <mat-icon>shuffle</mat-icon>&nbsp;Alias Mapping
                </button>
              </mat-list-item>
              <mat-list-item>
                <button mat-button fxFlex fxLayoutAlign="start center" [routerLink]="['/blacklist']"
                  (click)="menu.toggle()">
                  <mat-icon>contract_delete</mat-icon>&nbsp;Blacklist
                </button>
              </mat-list-item>
            </mat-expansion-panel>
            <mat-expansion-panel style="width:100%; box-shadow: none;">
              <mat-expansion-panel-header>Segnalazioni</mat-expansion-panel-header>
              <mat-panel-description>
                Segnalazioni aperte dagli utenti
              </mat-panel-description>
              <mat-list-item>
                <button mat-button fxFlex fxLayoutAlign="start center" [routerLink]="['/ticket-management']"
                  (click)="menu.toggle()">
                  <mat-icon>bug_report</mat-icon>&nbsp;Ticket management
                </button>
              </mat-list-item>
              <mat-list-item>
                <button mat-button fxFlex fxLayoutAlign="start center" [routerLink]="['/faq']" (click)="menu.toggle()">
                  <mat-icon>question_answer</mat-icon>&nbsp;FAQ
                </button>
              </mat-list-item>
            </mat-expansion-panel>
            <mat-expansion-panel style="width:100%;box-shadow: none;">
              <mat-expansion-panel-header>Affiliation</mat-expansion-panel-header>
              <mat-panel-description>
                Gestione degli Influencer
              </mat-panel-description>
              <mat-list-item>
                <button mat-button fxFlex fxLayoutAlign="start center" [routerLink]="['/gestione-affiliation']"
                  (click)="menu.toggle()">
                  <mat-icon>supervisor_account</mat-icon>&nbsp;Influencer
                </button>
              </mat-list-item>
            </mat-expansion-panel>

            <mat-expansion-panel style="width:100%;box-shadow: none;">
              <mat-expansion-panel-header>Subscription</mat-expansion-panel-header>
              <mat-panel-description>
                Gestione degli abbonamenti e codici promozionali
              </mat-panel-description>
              <mat-list-item>
                <button mat-button fxFlex fxLayoutAlign="start center" [routerLink]="['/promotion']"
                  (click)="menu.toggle()">
                  <mat-icon>local_activity</mat-icon>&nbsp;Promotions
                </button>
              </mat-list-item>
              <mat-list-item>
                <button mat-button fxFlex fxLayoutAlign="start center" [routerLink]="['/product']"
                  (click)="menu.toggle()">
                  <mat-icon>inventory_2</mat-icon>&nbsp;Products
                </button>
              </mat-list-item>
              <mat-list-item>
                <button mat-button fxFlex fxLayoutAlign="start center" [routerLink]="['/user-subscriptions']"
                  (click)="menu.toggle()">
                  <mat-icon>account_circle</mat-icon>&nbsp;User Subscriptions
                </button>
              </mat-list-item>
              <mat-list-item>
                <button mat-button fxFlex fxLayoutAlign="start center" [routerLink]="['/price-exchange']"
                  (click)="menu.toggle()">
                  <mat-icon>currency_exchange</mat-icon>&nbsp;Price Exchange
                </button>
              </mat-list-item>
              <mat-list-item>
                <button mat-button fxFlex fxLayoutAlign="start center" [routerLink]="['/subscription']"
                  (click)="menu.toggle()">
                  <mat-icon>subscriptions</mat-icon>&nbsp;Subscriptions
                </button>
              </mat-list-item>
              <mat-list-item>
                <button mat-button fxFlex fxLayoutAlign="start center" [routerLink]="['/receipt']"
                  (click)="menu.toggle()">
                  <mat-icon>receipt</mat-icon>&nbsp;Receipts
                </button>
              </mat-list-item>
              <mat-list-item>
                <button mat-button fxFlex fxLayoutAlign="start center" [routerLink]="['/subscription-extension']"
                  (click)="menu.toggle()">
                  <mat-icon>expand</mat-icon>&nbsp;Extensions
                </button>
              </mat-list-item>
              <mat-list-item>
                <button mat-button fxFlex fxLayoutAlign="start center" [routerLink]="['/stripe']"
                  (click)="menu.toggle()">
                  <mat-icon>assignment_turned_in</mat-icon>&nbsp;Quadrature Stripe
                </button>
              </mat-list-item>
            </mat-expansion-panel>

            <mat-expansion-panel style="width:100%;box-shadow: none;">
              <mat-expansion-panel-header>Notifications</mat-expansion-panel-header>
              <mat-panel-description>
                Gestione delle notifiche
              </mat-panel-description>
              <mat-list-item>
                <button mat-button fxFlex fxLayoutAlign="start center" [routerLink]="['/notifications/category']"
                  (click)="menu.toggle()">
                  <mat-icon>list_alt</mat-icon>&nbsp;Category
                </button>
              </mat-list-item>
              <mat-list-item>
                <button mat-button fxFlex fxLayoutAlign="start center" [routerLink]="['/notifications/configuration']"
                  (click)="menu.toggle()">
                  <mat-icon>settings</mat-icon>&nbsp;Category Configuration
                </button>
              </mat-list-item>
              <mat-list-item>
                <button mat-button fxFlex fxLayoutAlign="start center" [routerLink]="['/notifications/template']"
                  (click)="menu.toggle()">
                  <mat-icon>description</mat-icon>&nbsp;Template
                </button>
              </mat-list-item>
              <mat-list-item>
                <button mat-button fxFlex fxLayoutAlign="start center" [routerLink]="['/notifications/notifications']"
                  (click)="menu.toggle()">
                  <mat-icon>notifications</mat-icon>&nbsp;Notifications
                </button>
              </mat-list-item>
              <mat-list-item>
                <button mat-button fxFlex fxLayoutAlign="start center" [routerLink]="['/notifications/generate']"
                  (click)="menu.toggle()">
                  <mat-icon>notifications_active</mat-icon>&nbsp;Generate Notifications
                </button>
              </mat-list-item>
              <mat-list-item>
                <button mat-button fxFlex fxLayoutAlign="start center" [routerLink]="['/notifications/queue']"
                  (click)="menu.toggle()">
                  <mat-icon>queue</mat-icon>&nbsp;Notification Queue
                </button>
              </mat-list-item>
            </mat-expansion-panel>

            <mat-expansion-panel style="width:100%;box-shadow: none;">
              <mat-expansion-panel-header>Altro</mat-expansion-panel-header>
              <mat-accordion>
                <mat-expansion-panel style="width:100%;box-shadow: none;">
                  <mat-expansion-panel-header>Disclaimer</mat-expansion-panel-header>
                  <mat-list-item>
                    <button mat-button fxFlex fxLayoutAlign="start center" [routerLink]="['/disclaimers/message']"
                      (click)="menu.toggle()">
                      <mat-icon class="icon-font-size">format_align_center</mat-icon>&nbsp;Messages
                    </button>
                  </mat-list-item>
                </mat-expansion-panel>
    
                <mat-expansion-panel style="width:100%;box-shadow: none;">
                  <mat-expansion-panel-header>Change Log</mat-expansion-panel-header>
                  <mat-list-item>
                    <button mat-button fxFlex fxLayoutAlign="start center" [routerLink]="['/changeLogs']"
                      (click)="menu.toggle()">
                      <mat-icon class="icon-font-size">collections_bookmark</mat-icon>&nbsp;Change Logs
                    </button>
                  </mat-list-item>
                </mat-expansion-panel>

                <mat-expansion-panel style="width:100%;box-shadow: none;">
                  <mat-expansion-panel-header>Advertisements</mat-expansion-panel-header>
                  <mat-list-item>
                    <button mat-button fxFlex fxLayoutAlign="start center" [routerLink]="['/campaigns']"
                    (click)="menu.toggle()">
                    <mat-icon>label</mat-icon>&nbsp;Advertisements
                  </button>
                  </mat-list-item>
                </mat-expansion-panel>

              </mat-accordion>
              <mat-list-item>
              </mat-list-item>
            </mat-expansion-panel>


            <mat-list-item>
              <button mat-button fxFlex fxLayoutAlign="start center" [routerLink]="['/about']" (click)="menu.toggle()">
                <mat-icon>copyright</mat-icon>&nbsp;About
              </button>
            </mat-list-item>
            <mat-list-item>
              <button mat-button fxFlex fxLayoutAlign="start center" (click)="logout()">
                <mat-icon>exit_to_app</mat-icon>&nbsp;Logout
              </button>
            </mat-list-item>
          </mat-list>
        </mat-accordion>
      </mat-sidenav>
      <mat-sidenav-content fxFlex="100" fxLayout="row">
        <mat-card fxLayout="column" fxFlex class="mainContent">
          <mat-card-content fxLayout="column" fxFlex>
            <div class="mainContentBody">
              <router-outlet></router-outlet>
            </div>
          </mat-card-content>
        </mat-card>
      </mat-sidenav-content>
    </mat-sidenav-container>
  </div>
  <mat-toolbar fxLayout="row" ngClass-xs="footerToolbarXs footerToolbarTextXs" ngClass="footerToolbar footerToolbarText"
    color="primary">
    Japp &copy; &nbsp;&nbsp;<a href="https://www.jenia.it" style="color:white;">JENIA Software</a>
  </mat-toolbar>
</div>