import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { CryptoScamFakeDto } from 'src/app/shared/dto/core-catalog/CryptoScamFakeDto.types';
import { CryptoScamFakeFilterDto } from 'src/app/shared/dto/core-catalog/CryptoScamFakeFiltersDto.types';
import { CryptoScamFakeWrapper } from 'src/app/shared/dto/core-catalog/CryptoScamFakeWrapper.types';
import { Pageable } from 'src/app/shared/dto/Pageable.types';
import { PageDTO } from 'src/app/shared/dto/PageDTO';
import { GenericService } from 'src/app/shared/GenericService';
import { environment } from 'src/environments/environment';

@Injectable()
export class BlacklistService extends GenericService<CryptoScamFakeDto, string>{
    constructor(protected http: HttpClient) {
        super(http);
        this.url = environment.urlPrefix + '/arzakena-core-catalog/blacklist';
    }

    getCatalogsCryptos(pageable: Pageable, filters: CryptoScamFakeFilterDto): Observable<PageDTO<CryptoScamFakeDto>>{
        const url = this.url + '/readAllByFilters';
        const page = {'page': pageable.page + '', 'size': pageable.size + '', 'sort': pageable.sort};
        return this.http.post<PageDTO<CryptoScamFakeDto>>(url, filters, { params: page });
    }

    getUsersCryptos(contentIsWhitelist: boolean, pageable: Pageable, filters: CryptoScamFakeFilterDto): Observable<PageDTO<CryptoScamFakeWrapper>>{
        const url =  contentIsWhitelist ? '/arzakena-be-blacklist/blacklist/whitelistedCryptos' : '/arzakena-be-blacklist/blacklist/blacklistedCryptos';
        const page = {'page': pageable.page + '', 'size': pageable.size + '', 'sort': pageable.sort+''};
        Object.keys(page).forEach(key => page[key] === 'undefined' && delete page[key]);
        return this.http.post<PageDTO<CryptoScamFakeWrapper>>(environment.urlPrefix + url, filters, { params: page });
    }
}
