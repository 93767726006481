import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { TimestampFormatPipe } from 'src/app/shared/pipes/timestampFormatPipe';
import { NavigatorService } from 'src/app/services/navigator.service';
import { GenericDetailComponent } from 'src/app/shared/GenericDetailComponent';
import { SmartContractAddressMappingDto } from 'src/app/shared/dto/core-catalog/SmartContractAssetMappingDto.types';
import { blockchainTypes } from 'src/app/shared/dto/commons/BlockchainType.types';
import { BlacklistService } from 'src/app/services/core-catalog/blacklist/blacklist.service';
import { AssetDefinitionAliasWrapper } from 'src/app/core-catalog/smart-contract-address-mapping/smart-contract-address-mapping-detail/asset-definition-input/asset-definition-input.component';
import { CryptoScamFakeDto } from 'src/app/shared/dto/core-catalog/CryptoScamFakeDto.types';

@Component({
  selector: 'app-list-scam-fake-detail',
  templateUrl: './list-scam-fake-detail.component.html',
  styleUrls: ['./list-scam-fake-detail.component.scss'],
  providers: [
    BlacklistService
  ]
})
export class ListScamFakeDetailComponent extends GenericDetailComponent implements OnInit, OnDestroy {

  readonly blockchainTypes = blockchainTypes;

  private _unsubscribeAll: Subject<void> = new Subject<void>();

  constructor(
    private blacklistService: BlacklistService,
    activeRoute: ActivatedRoute,
    dialog: MatDialog,
    snackBar: MatSnackBar,
    timestampFormatPipe: TimestampFormatPipe,
    router: Router,
    navigatorService: NavigatorService,
    dateAdapter: DateAdapter<Date>,
    private _formBuilder: FormBuilder
  ) {
    super(
      navigatorService,
      dialog,
      router,
      dateAdapter,
      activeRoute,
      snackBar,
      timestampFormatPipe);
  }

  ngOnInit(): void {
    if(this.detailId()) {
      this.read(this.detailId());
    } else {
      this.form = this.loadForm(new CryptoScamFakeDto());
    }
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  save(): void {
    this.form.markAsPristine();
    if(this.detailId()) {
      this.update(this.toCryptoScamFakeDto());
      return;
    }
    this.create(this.toCryptoScamFakeDto());
  }

  deleteCryptoScamFake(): void {
    if(this.detailId()) {
      this.confirm('Sei sicuro di voler cancellare la mappatura?')
        .pipe(
          takeUntil(this._unsubscribeAll),
          filter(choice => choice)
        ).subscribe(() => this.delete(this.detailId()));
    }
  }

  detailId(): string {
    return this.activeRoute.snapshot.paramMap.get('id');
  }

  private create(cryptoScamFakeDto: CryptoScamFakeDto): void {
    this.blacklistService.create(cryptoScamFakeDto).subscribe((responseMapping) => {
      this.router.navigateByUrl(this.router.url.replace(this.detailId() + '', responseMapping.id + ''));
      this.form = this.loadForm({...new CryptoScamFakeDto(), ...responseMapping});
    });
  }

  private update(cryptoScamFakeDto: CryptoScamFakeDto): void {
    this.blacklistService.update(cryptoScamFakeDto).subscribe(() => this.read(this.detailId()));
  }

  private read(id: string): void {
    this.blacklistService.read(id)
    .pipe(
      takeUntil(this._unsubscribeAll)
    ).subscribe(cryptoScamFakeDto => this.form = this.loadForm({...new CryptoScamFakeDto(), ...cryptoScamFakeDto}));
  }

  private delete(id: string): void {
    this.blacklistService.delete(id).subscribe(() => this.router.navigate(['/listScamFake']));
  }
  private loadForm(cryptoScamFakeDto: CryptoScamFakeDto): FormGroup {
    return this._formBuilder.group({...cryptoScamFakeDto, ...this.formValidators(cryptoScamFakeDto)});
  }

  private toCryptoScamFakeDto(): CryptoScamFakeDto {
    return this.form.getRawValue() as CryptoScamFakeDto;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  private formValidators(cryptoScamFakeDto: CryptoScamFakeDto) {
    return {
        symbol: [cryptoScamFakeDto.symbol, [Validators.maxLength(255)]],
        tokenAddress: [cryptoScamFakeDto.tokenAddress, [Validators.required, Validators.maxLength(255)]],
        blockchainType: [cryptoScamFakeDto.blockchainType, [Validators.required, Validators.maxLength(255)]]
    };
  }
}
