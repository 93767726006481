import { TraceModificationDTO } from '../basic/traceModificationDTO';
import { BlockchainType } from '../commons/BlockchainType.types';

export class CryptoScamFakeDto extends TraceModificationDTO {

	id: number;
	symbol: string;
	tokenAddress: string;
	blockchainType: BlockchainType;

    constructor() {
        super();
        this.id = undefined;
        this.symbol = '';
        this.tokenAddress = '';
        this.blockchainType = undefined;
    }
}
