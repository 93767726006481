<mat-card id="card" fxLayout="column" class="mainContentBody">
    <mat-card-content fxFlex [style.overflow]="'auto'" fxLayout="column" class="mainContentBodyContent">
      <h2>Lista delle crypto SCAM e FAKE</h2>
      <p>Imposta il filtro di ricerca</p>
      <div fxLayout="column" class="responsive-mat-form">
  
        <mat-form-field>
          <input matInput [(ngModel)]="parameters.filter" placeholder="Filter" />
        </mat-form-field>
  
        <mat-form-field>
          <mat-select [(ngModel)]="parameters.builtOn" placeholder="Built on blockchain" #typeSelect>
            <mat-option *ngIf="!typeSelect.empty && typeSelect.panelOpen"></mat-option>
            <mat-option *ngFor="let blockchainType of blockchainTypes" [value]="blockchainType">
              {{blockchainType}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <mat-card-actions>
        <button mat-raised-button id="search-button" (click)="list()" name="Search" color="primary">Search</button>
        <button mat-raised-button id="new-button" [routerLink]="['/listScamFake/detail/0']">New</button>
        <button mat-raised-button (click)="back('/')" color="primary">Back</button>
      </mat-card-actions>
      <br />
      <!-- [(selectedIndex)]="selectedIndex" #tab -->
      <mat-tab-group mat-stretch-tabs (selectedTabChange)="selectedTabChange($event)" [animationDuration]="'0'" [(selectedIndex)]="selectedIndex">
        <ng-container *ngFor="let tab of tabs; trackBy: trackByFn">
          <mat-tab [label]="tab" [aria-label]="tab">
            <div *ngIf="parameters.showList && parameters.dataSource" fxLayout="column">
              <mat-card-actions class="pt-5" fxLayout="row" fxLayoutAlign="end center">
                <button *ngIf="tab === 'Crypto SCAM e FAKE degli Utenti'" mat-raised-button (click)="changeContent()" color="primary">
                  <ng-container *ngIf="!contentIsWhitelist; else whitelist">
                    Visualizza Whitelist Users
                  </ng-container>
                  <ng-template #whitelist>
                    Visualizza Blacklist Users
                  </ng-template>
                </button>
              </mat-card-actions>
              <ng-container *ngIf="parameters.dataSource.length; else noContent">
                
                <table class="pt-5" mat-table fxFlex matTableExporter #exporter="matTableExporter" matSort (matSortChange)="setSort($event)"
                  matSortDisableClear [dataSource]="parameters.dataSource">
        
                  <!-- id Column -->
                  <ng-container matColumnDef="id">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Id </th>
                    <td mat-cell *matCellDef="let smartContractAddressMapping"> {{smartContractAddressMapping.id}} </td>
                  </ng-container>
        
                  <!-- title Column -->
                  <ng-container matColumnDef="smartContractAddress">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Smart Contract Address </th>
                    <td mat-cell *matCellDef="let smartContractAddressMapping"> {{smartContractAddressMapping.tokenAddress}} </td>
                  </ng-container>
        
                  <ng-container matColumnDef="nUtenti">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> nUtenti </th>
                    <td mat-cell *matCellDef="let smartContractAddressMapping"> {{smartContractAddressMapping.count}} </td>
                  </ng-container>

                  <!-- assetSymbol Column -->
                  <ng-container matColumnDef="assetDefinition.symbol">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Asset Symbol </th>
                    <td mat-cell *matCellDef="let smartContractAddressMapping"> {{smartContractAddressMapping.symbol}} </td>
                  </ng-container>
        
                  <!-- type Column -->
                  <ng-container matColumnDef="builtOn">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Built on </th>
                    <td mat-cell *matCellDef="let smartContractAddressMapping"> {{smartContractAddressMapping.blockchainType}} </td>
                  </ng-container>

                  <ng-container matColumnDef="detail">
                    <th mat-header-cell *matHeaderCellDef></th>
                    <td mat-cell *matCellDef="let smartContractAddressMapping">
                      <button mat-mini-fab color="primary" [routerLink]="['/listScamFake/detail/', smartContractAddressMapping.id]">
                        <mat-icon>search</mat-icon>
                      </button>
                    </td>
                  </ng-container>
        
                  <ng-container matColumnDef="add">
                    <th mat-header-cell *matHeaderCellDef></th>
                    <td mat-cell *matCellDef="let smartContractAddressMapping">
                      <!-- provare a passare smartContractAddressMapping.id al posto di 0 -->
                      <button mat-mini-fab color="primary" (click)="addCryptoToCoreBlacklist(smartContractAddressMapping)">
                        <mat-icon>add</mat-icon>
                      </button>
                    </td>
                  </ng-container>
        
                  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>
                <div fxLayout="row" fxLayoutAlign="space-between center">
                  <mat-paginator [pageSize]="parameters.size" [length]="parameters.length" [pageSizeOptions]="[10,50,100]"
                    (page)="setPaginator($event)"></mat-paginator>
                </div>
              </ng-container>
              <ng-template #noContent>
                <h2>Nessuna mappatura trovata</h2>
              </ng-template>
            </div>
          </mat-tab>
        </ng-container>
      </mat-tab-group>
    </mat-card-content>
</mat-card>